import LayoutUtils from 'src/utils/layout_utils';

class ScheduleShowingIframeModal {
  constructor(link, modalId, modalTemplateSelector) {
    this.link = link;
    this.modalId = modalId;
    this.modalTemplateSelector = modalTemplateSelector;
  }

  handleClick(event) {
    event.preventDefault();

    document.dispatchEvent(new CustomEvent('sidebar:popup-close'));

    LayoutUtils.show(this.spinner);

    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
      content: document.querySelector(this.modalTemplateSelector).innerHTML,
      onComplete: () => {
        this.modal = document.getElementById(this.modalId);
        this.spinner = document.querySelector(`#${this.modalId} .spinner`);
        this.iframe = document.querySelector(`#${this.modalId} .iframe`);
        this.iframe.style.height = '400px';
        this.iframe.setAttribute('src', event.target.href);
        this.iframe.addEventListener('load', () => {
          LayoutUtils.hide(this.spinner);
        });

        document.dispatchEvent(new CustomEvent('showing-modal:opened'));
      },
      beforeClose: this.handleClose.bind(this)
    }}));

  }

  resizeIframe(value) {
    let height;

    if (typeof value === 'object') {
      height = value.height;
    } else {
      height = value;
    }

    if (this.iframe) {
      if (height != 0) {
        this.iframe.style.height = `${height}px`;
        document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
      } else {
        this.iframe.style.height = '100%';
      }
    }
  }

  closePopup(timeout) {
    setTimeout(function() {
      document.dispatchEvent(new CustomEvent('sm-modal:close'));
    }.bind(this), timeout);
  }

  scrollToTop() {
    this.modal.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleMessage(e) {
    if (typeof(e.data) != 'string') { return; }

    const [action, data] = e.data.split('::');

    if (action != 'SIZING' && action != 'CLOSING' && action != 'SHOWING_RETAINED' && action != 'SHOWING_SCHEDULED') {
      return;
    }

    const value = data ? JSON.parse(data) : 0;

    switch (action) {
    case 'SIZING':
      this.resizeIframe(value);
      break;
    case 'CLOSING':
      this.closePopup(0);
      break;
    case 'SHOWING_RETAINED':
      this.scrollToTop();
      break;
    case 'SHOWING_SCHEDULED':
      this.scrollToTop();
      this.closePopup(5000);
    }
  }

  handleClose() {
    this.iframe.setAttribute('src', '');
  }

  init() {
    this.link.addEventListener('click', this.handleClick.bind(this));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const iframeLinks = document.querySelectorAll('[data-schedule-showing-iframe-modal="true"]');
  for (const link of iframeLinks) {
    const modal = new ScheduleShowingIframeModal(link, 'schedule-showing-iframe-modal', '#schedule-showing-iframe-modal-template');

    window.addEventListener('message', modal.handleMessage.bind(modal), false);
    modal.init();
  }
});
