class MojoSelect {
  #target;
  #quick_search;
  #list;
  #autocomplete_data = [];
  #lazy;
  #loaded_listing_label;

  constructor() {
    this.showAllItems = this.showAllItems.bind(this);
  }

  toggleClick(elems, methods) {
    let count = methods.length; // cache the number of methods
    elems.forEach(function(item){
      // for each element you bind to
      var index = 0; // create a local counter for that element
      item.addEventListener('click', function(){ // bind a click handler to that element
        return methods[index++ % count].apply(this, arguments); // that when called will apply the 'index'th method to that element
        // the index % count means that we constrain our iterator between 0 and (count-1)
      });
    });
  }

  hideInactive(e) {
    const value = e.detail.value;
    this.#quick_search = this.#target.querySelector('.combobox_quick_search');

    if (value) {
      this.#target.classList.add('hide_inactive');
    } else {
      this.#target.classList.remove('hide_inactive');
    }
    this.#quick_search.dispatchEvent(new Event('keyup'));
  }

  appendExistingParams(el) {
    var href = window.location.href;
    var url = new URL(href);
    var searchParams = new URLSearchParams(url.search);

    searchParams.forEach(function(value, key) {
      const input = el.querySelector('input[name="' + key + '"]');
      if (!input || !input.value) {
        el.querySelector('form').insertAdjacentHTML('beforeend', `<input type="hidden" name="${key}" value="${value}" />`);
      }
    });
  }

  showAllItems() {
    var hide_inactive = this.#target.classList.contains('hide_inactive');
    for (var i = this.#autocomplete_data.length - 1; i >= 0; i--) {
      if( (!hide_inactive || this.#autocomplete_data[i].active) ) {
        this.#autocomplete_data[i].item.style.display = 'block';
      } else {
        this.#autocomplete_data[i].item.style.display = 'none';
      }
    }
  }

  initComboboxItemsForNonLazy() {
    this.#list.querySelectorAll('.combobox_item').forEach((item) => {
      const text = item.querySelector('.combobox_item_title') ? item.querySelector('.combobox_item_title').textContent : '';
      this.#autocomplete_data.push( {
        item: item,
        string: text + ' ' +
          item.dataset.autocomplete,
        active: (item.dataset.active !== 'false')
      } );
    });
  }

  handleNavigation(event) {
    var selected = [...this.#list.querySelectorAll('.hover')].filter(el => el.style.display !== 'none');

    switch(event.keyCode){
    case 27: // Escape
      this.#target.querySelector('.combobox_title').click();
      break;
    case 38: // Up
      var prev = null;
      if(selected.length === 1) {
        prev = selected[0].previousElementSibling;
        if (prev) {
          prev.classList.add('hover');
        }
        selected[0].classList.remove('hover');
      }
      else {
        const visibleElements = [...this.#list.querySelectorAll('.combobox_item')].filter(el => el.style.display !== 'none');
        if (visibleElements.length > 0) {
          prev = visibleElements[visibleElements.length - 1].classList.add('hover');
        }
      }
      if (prev) {
        this.#list.scrollBy(0,-50);
      }
      break;
    case 40: // Down
      var next = null;
      if(selected.length === 1) {
        next = selected[0].nextElementSibling;
        if (next) {
          next.classList.add('hover');
        }

        selected[0].classList.remove('hover');
      }
      else {
        const visibleElements = [...this.#list.querySelectorAll('.combobox_item')].filter(el => el.style.display !== 'none');
        if (visibleElements.length > 0) {
          next = visibleElements[0].classList.add('hover');
        }
      }
      if (next) {
        this.#list.scrollBy(0,50);
      }
      break;
    case 13: // Enter
      if(selected.length === 1) {
        selected[0].click();
      }
      break;
    }
  }

  handleLazyLoad(last_search_request, last_query, last_hide_inactive, search_query, hide_inactive) {
    if (search_query !== this.#loaded_listing_label && search_query.length >= 3) {
      if (search_query !== last_query || hide_inactive !== last_hide_inactive) {

        if (last_search_request) {
          clearTimeout(last_search_request);
          last_search_request = null;
        }

        let search_func = () => {
          this.#list.innerHtml = document.querySelector('#combobox-item-loading-template').innerHTML;

          const url = '/listings/switcher/search?query=' + search_query +
            '&hide_inactive=' + hide_inactive +
            '&selected_listing=' + this.#target.dataset.selectedListing;
          fetch(url, {headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }}).then(FetchUtils.checkResponseStatus)
          .then((resp) => {
            return resp.json();
          }).then(json => {
            document.querySelector('#listing_switcher .combobox_list').innerHTML = json.html || document.querySelector('#no-listing-template').innerHTML;
            document.querySelector('#listing_switcher .js-sm-mojo-select').dispatchEvent(new CustomEvent('mojo-select:list-updated'));
          }).catch((err) => {
            FetchUtils.handleResponseError(err);
          });
        };

        if (hide_inactive !== last_hide_inactive) {
          /* Avoid this.#list update being delayed if all the user did is switch between all
            * this.#listings visible and on-market only */
          search_func();
        } else {
          /* Delay a bit to make sure the user has finished typing in the search term */
          last_search_request = setTimeout(function() {
            search_func();
          }, 500);
        }
      }
    } else {
      if (last_search_request) {
        clearTimeout(last_search_request);
        last_search_request = null;
      }

      document.querySelector('#listing_switcher .combobox_list').innerHTML
        = document.querySelector('#search-instructions-template').innerHTML;

      this.bindShowAll();
    }

    last_query = search_query;
    last_hide_inactive = hide_inactive;
  }

  bindShowAll() {
    const target = this.#target;
    const showAll = target.querySelector('.show_all_items');
    if (showAll) {
      ['mouseenter', 'mouseleave'].forEach((evt) => {
        showAll.addEventListener(evt, function(e){e.preventDefault();});
      });
      showAll.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.#lazy = false;
        this.#list.innerHtml = document.querySelector('#combobox-item-loading-template').innerHTML;

        fetch(e.target.href, {headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(json => {
          document.querySelector('#listing_switcher .combobox_list').innerHTML = json.html;
          this.initComboboxItemsForNonLazy();
          this.#quick_search.dispatchEvent(new Event('keyup'));
          this.bindClickOnListItem(target);
        }).catch((err) => {
          FetchUtils.handleResponseError(err);
        });

        return false;
      });
    }
  }

  bindClickOnListItem() {
    const appendExistingParams = this.appendExistingParams;
    const self = this;
    this.#target.querySelectorAll('.combobox_item').forEach((el) => {
      el.addEventListener('click', function(e){
        if (this.classList.contains('disabled')) return;
        e.preventDefault();
        self.#quick_search.value = this.dataset.title || this.dataset.autocomplete;
        self.#quick_search.dispatchEvent(new CustomEvent('change', {detail: {item: el}}));
        self.#list.style.display = 'none';
        self.#target.classList.remove('combobox_active');

        var uid = this.dataset.uid;
        const current = this.closest('.combobox_list').querySelector('.combobox_item_current');
        var currentUid = current ? current.dataset.uid : self.#target.dataset.selectedListing;
        if (uid && currentUid && (uid !== currentUid)) {
          var href = window.location.href;
          window.location.href = href.replace(currentUid, uid);
        } else {
          appendExistingParams(this);
          const form = this.querySelector('form');
          if (form) {
            form.submit();
          }
          self.#target.dispatchEvent(new CustomEvent('change', {detail: this.dataset}));
        }
      });
    });
  }

  bindQuickSearchEvents() {
    var last_search_request;
    var last_query;
    var last_hide_inactive;

    ['keyup', 'input', 'paste'].forEach((e) => {
      this.#quick_search.addEventListener(e, (event) => {
        if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13 || event.keyCode === 27) {
          this.handleNavigation(event);
        } else {
          var search_query = this.#quick_search.value;
          var hide_inactive = this.#target.classList.contains('hide_inactive');

          if (this.#lazy) {
            this.handleLazyLoad(last_search_request, last_query, last_hide_inactive, search_query, hide_inactive);
          } else {
            this.#list.querySelectorAll('.combobox_item').forEach(el => el.style.display = 'none');
            if(search_query !== '' && search_query !== this.#loaded_listing_label) {
              const hovered = this.#list.querySelector('.hover');
              if (hovered) {
                hovered.classList.remove('.hover');
              }

              var match_regex = new RegExp(search_query, 'i');
              for (var i = this.#autocomplete_data.length - 1; i >= 0; i--) {
                var string = this.#autocomplete_data[i].string;

                if((!hide_inactive || this.#autocomplete_data[i].active)
                  && string.match(match_regex)) {
                  // Show matched items
                  this.#autocomplete_data[i].item.style.display = 'block';
                }
              }
            }
            else {
              this.showAllItems();
            }
          }
        }
      });
    });
  }

  bindToggleClickOnTitle() {
    const target = this.#target;
    const showAllItems = this.showAllItems;
    function preventClick(event) {
      event.stopPropagation();
    }
    function clickByComboboxTitle() {
      target.querySelector('.combobox_title').click();
    }
    const self = this;
    this.toggleClick(target.querySelectorAll('.combobox_title'),
      [function(event){
        event.stopPropagation();
        self.#quick_search.addEventListener('click', preventClick);
        if (this.hasAttribute('disabled')) return;
        document.querySelector('body').addEventListener('click', clickByComboboxTitle);

        target.classList.add('combobox_active');
        const input = target.querySelector('.title_text input');
        if (input) {
          input.select();
        }

        self.#list.style.display = 'block';
        showAllItems(target);
      },
      function(){
        document.querySelector('body').removeEventListener('click', clickByComboboxTitle);
        self.#quick_search.removeEventListener('click', preventClick);
        target.classList.remove('combobox_active');
        showAllItems(target);
        self.#list.style.display = 'none';
      }]
    );
  }

  bindHoverOnListItem() {
    this.#list.querySelectorAll('.combobox_item').forEach((el) => {
      el.addEventListener('mouseenter', (e) => {
        this.#list.querySelectorAll('.combobox_item.hover').forEach((el) => {
          el.classList.remove('hover');
        });
        e.target.classList.add('hover');
      });
    });

    this.#list.querySelectorAll('.combobox_item').forEach((el) => {
      el.addEventListener('mouseleave', function(){
        this.classList.remove('hover');
      });
    });
  }

  bindEvents() {
    const target = this.#target;
    target.addEventListener('hide_inactive', (e) => this.hideInactive(e));
    target.addEventListener('mojo-select:list-updated', () => this.bindClickOnListItem());
    this.bindQuickSearchEvents();
    this.bindToggleClickOnTitle();
    this.bindHoverOnListItem();
    this.bindClickOnListItem();
  }

  init(target, options) {
    this.#list   = target.querySelector('.combobox_list');
    this.#quick_search = target.querySelector('.combobox_quick_search');
    this.#lazy = target.classList.contains('lazy');
    this.#loaded_listing_label = this.#quick_search.value;
    this.#quick_search.setAttribute('autocomplete', 'off');
    this.#target = target;

    if (options && options['hide_inactive']) {
      target.classList.add('hide_inactive');
    } else {
      target.classList.remove('hide_inactive');
    }
    target.querySelectorAll('input[type="submit"]').forEach((el) => el.style.display = 'none');

    this.initComboboxItemsForNonLazy(this.#autocomplete_data, this.#list);
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initSelects(wrapper = 'body') {
    document.querySelectorAll(`${wrapper} .js-sm-mojo-select`).forEach(el => {
      if (!el.dataset.mojoSelectInited) {
        el.dataset.mojoSelectInited = true;
        new MojoSelect().init(el);
      }
    });
  }
  initSelects();

  document.addEventListener('html-updated', (e) => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initSelects(wrapper);
  });

  document.addEventListener('sm-modal:opened', () => {
    initSelects('.js-sm-modal');
  });
});
